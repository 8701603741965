type ZIndexGenerator = (index?: number, start?: number) => number
type ZIndexGeneratorFactory = (start?: number) => ZIndexGenerator

export class ZIndex {
  private factory: ZIndexGeneratorFactory

  private zIndexes: Record<string, ZIndexGenerator> = {}

  constructor(factory: ZIndexGeneratorFactory) {
    this.factory = factory
    // console.log(this.zIndexes)
  }

  nextIndex(prefix: string, start = 0) {
    if (!this.zIndexes[prefix]) {
      this.zIndexes[prefix] = this.factory(start)
    }

    // console.log(this.zIndexes)
    const index = this.zIndexes[prefix]()
    // console.log(index)

    return index
  }

  clearIndex(prefix: string) {
    if (this.zIndexes[prefix]) return this.zIndexes[prefix](-1)
    return undefined
  }
}

export function globalZIndexGeneratorFactory(start = 0) {
  let index = start
  return (o = 1) => (index += o)
}
