import { TooltipProvider } from '@radix-ui/react-tooltip'
import { AuthProvider, createUrqlClient, WithAuthRedirect } from '@upper/auth'
import { ZIndexProvider } from '@upper/providers'
import { cacheExchange } from '@urql/exchange-graphcache'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import * as React from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'urql'
import './styles.css'
import '@upper/sapphire/ui/style'


type NextPageWithLayoutAndAuth = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
  authenticate?: boolean
  redirectAuthenticatedTo?: string
}

type AppPropsWithLayoutAndAuth = AppProps & {
  Component: NextPageWithLayoutAndAuth
}

const APP_NAME = 'public'

const urqlClient = createUrqlClient(
  APP_NAME,
  cacheExchange({
    updates: {
      Mutation: {
        createEngagement(_result, args, cache, _info) {
          cache.invalidate(
            {
              __typename: 'Query',
            },
            'engagements',
            {
              offset: 0,
              limit: 99,
            }
          )
        },
      },
    },
  })
)

function CustomApp({ Component, pageProps }: AppPropsWithLayoutAndAuth) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_PUBLIC}');
          `,
        }}
      />
      <Provider value={urqlClient}>
        <AuthProvider appName={APP_NAME}>
          <WithAuthRedirect
            authenticate={Component.authenticate}
            redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
          >
            <ZIndexProvider>
              <TooltipProvider>
                {getLayout(<Component {...pageProps} />)}
              </TooltipProvider>
            </ZIndexProvider>
          </WithAuthRedirect>
        </AuthProvider>
        <Toaster />
      </Provider>
    </>
  )
}

export default CustomApp
